import {useNavigate } from 'react-router-dom';
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation } from "swiper/modules";
import "swiper/css";
import 'swiper/css/navigation';
import { STORAGEURL } from '..';
import { useContext, useEffect, useRef, useState } from 'react';
import { AppContext } from '../routes/Layout';

const Uvod = ({data}) => { 
    const navigate = useNavigate();  
    const {pt}= useContext(AppContext);
    const ref = useRef();
    const [h,setH] = useState(window.innerWidth>600?(0.85*window.innerHeight):(0.5*window.innerHeight));

    useEffect(()=>{
        if (ref.current&&pt&&window.innerWidth>600){
            const minBox = ref.current.scrollHeight + 40;
            const min = 0.85*window.innerHeight-pt;
            setH(Math.max(min,minBox));
        }
    },[ref,pt])
    return (
        <section className="bg-[#F6F6F6]"> 
            <div id='intro' className={"introbg text-white max-w-[100vw] w-full tablet:pb-0 flex flex-col tablet:flex-col-reverse justify-center gap-8 mobil:gap-0"}>  
                <Swiper
                    navigation={true}
                    modules={[Autoplay,Navigation]}
                    autoplay={{ delay: 3000 }} 
                    className='introSwiper'
                    loop={true}
                >
                    {data?.galerie.split("~")?.map(img=><SwiperSlide key={img} className={"mobil:h-[50vh] !w-[100vw] bg-cover self-stretch bg-no-repeat bg-[20%_center]"} style={{backgroundImage:"url('"+STORAGEURL+img +"')", height:h+'px'}}></SwiperSlide>)}

                </Swiper>
                <div ref={ref} className='absolute right-vw mobil:relative mobil:right-0 m-auto lg:w-1/3 max-h-[90%] min-h-max max-w-[90vw] mobil:max-w-full mobil:w-full bg-blues-300 p-10 z-10 rounded-4xl mobil:rounded-none'>
                    <span className='uppercase'>{data?.intro_subheader}</span>
                    <h1 className="megadesktop:text-5xl w-2xl leading-tight text-4xl tablet:max-w-full">{data?.intro_header} </h1>
                    <div className="w-xl parsedDiv">{data?.intro_text} </div>
                    <button className='button' onClick={()=>navigate(data?.intro_button_URL)}>{data?.intro_button_text}</button>
                </div>
            </div> 
        </section>
    )
}

export default Uvod